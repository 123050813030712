import { merge } from "lodash"
import * as defaultMaterial from "@common/config/material-type"

const DEFAULT_CONFIGURATION = defaultMaterial.DEFAULT_CONFIGURATION

// Service specific modifications can be done here
const NEWS_ARTICLE_CUSTOM = {
  // Example for overriding default material configuration
  title: "label.newsArticle",
}
const STATUTE_AMENDMENT_CUSTOM = {
  configuration: {
    actions: { edilexStatuteAmendment: true },
    listing: {
      customLink: (document) => {
        const URL = `https://www.finlex.fi/fi/laki/alkup/${document.year}/${document.documentKey}`

        return URL
      },
    },
    sidebar: {
      // Override the detaul material type (statute-amendment) with something else
      updateInfo: defaultMaterial.STATUTE_CONSOLIDATED.materialType,
    },
  },
}

// Combine custom configuration to default
const ENVIRONMENT_BULLETIN = defaultMaterial.ENVIRONMENT_BULLETIN
const EU_STATUTE_CONSOLIDATED = defaultMaterial.EU_STATUTE_CONSOLIDATED
const GOVERNMENT_BILL = defaultMaterial.GOVERNMENT_BILL
const MODULE_OTHER_DOCUMENT = defaultMaterial.MODULE_OTHER_DOCUMENT
const NEWS_ARTICLE = merge(
  {},
  defaultMaterial.NEWS_ARTICLE,
  NEWS_ARTICLE_CUSTOM
)
const STATUTE_AMENDMENT = merge(
  {},
  defaultMaterial.STATUTE_AMENDMENT,
  STATUTE_AMENDMENT_CUSTOM
)
const STATUTE_CONSOLIDATED = defaultMaterial.STATUTE_CONSOLIDATED
const STATUTE_METADATA = defaultMaterial.STATUTE_METADATA

const ALL = {
  [ENVIRONMENT_BULLETIN.materialType]: ENVIRONMENT_BULLETIN,
  [EU_STATUTE_CONSOLIDATED.materialType]: EU_STATUTE_CONSOLIDATED,
  [GOVERNMENT_BILL.materialType]: GOVERNMENT_BILL,
  [MODULE_OTHER_DOCUMENT.materialType]: MODULE_OTHER_DOCUMENT,
  [NEWS_ARTICLE.materialType]: NEWS_ARTICLE,
  [STATUTE_AMENDMENT.materialType]: STATUTE_AMENDMENT,
  [STATUTE_CONSOLIDATED.materialType]: STATUTE_CONSOLIDATED,
  [STATUTE_METADATA.materialType]: STATUTE_METADATA,
}

export {
  ALL,
  DEFAULT_CONFIGURATION,
  ENVIRONMENT_BULLETIN,
  EU_STATUTE_CONSOLIDATED,
  GOVERNMENT_BILL,
  MODULE_OTHER_DOCUMENT,
  NEWS_ARTICLE,
  STATUTE_AMENDMENT,
  STATUTE_CONSOLIDATED,
  STATUTE_METADATA,
}
