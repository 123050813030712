import OidcCallback from "@wrappers/helpers/OidcCallback.vue"
// Use commonly defined routes, and customize them here if needed.
// Import section functions
import {
  ENVIRONMENT_BULLETIN as ENVIRONMENT_BULLETIN_ROUTE,
  EU_STATUTE_CONSOLIDATED as EU_STATUTE_CONSOLIDATED_ROUTE,
  GOVERNMENT_BILL as GOVERNMENT_BILL_ROUTE,
  NEWS_ARTICLE as NEWS_ARTICLE_ROUTE,
  STATUTE_CONSOLIDATED as STATUTE_CONSOLIDATED_ROUTE,
  STATUTE_METADATA as STATUTE_METADATA_ROUTE,
} from "@common/router/routes"
import { makeGroupRoute } from "@common/router/utils"

// Use customized material configuration for routes
// Do not change material configuration here
import {
  ENVIRONMENT_BULLETIN,
  EU_STATUTE_CONSOLIDATED,
  GOVERNMENT_BILL,
  NEWS_ARTICLE,
  STATUTE_CONSOLIDATED,
  STATUTE_METADATA,
} from "@/config/material-type"

// Create material routes using customized configuration
const environmentBulletin = ENVIRONMENT_BULLETIN_ROUTE(ENVIRONMENT_BULLETIN)
const euStatuteConsolidated = EU_STATUTE_CONSOLIDATED_ROUTE(EU_STATUTE_CONSOLIDATED)
const governmentBill = GOVERNMENT_BILL_ROUTE(GOVERNMENT_BILL)
const newsArticle = NEWS_ARTICLE_ROUTE(NEWS_ARTICLE)
const statuteConsolidated = STATUTE_CONSOLIDATED_ROUTE(STATUTE_CONSOLIDATED)
const statuteReferenceCard = STATUTE_METADATA_ROUTE(STATUTE_METADATA)

// Create group routes and their main route ("index")
const statuteGroup = makeGroupRoute([
  statuteConsolidated, // First item determines navigation item (label)
  euStatuteConsolidated,
  statuteReferenceCard,
])

// Ordering here determines navigation ordering. Do NOT sort them alphabetically
// like everywhere else!
export default [
  {
    path: "auth/login-callback",
    name: "login-callback",
    component: OidcCallback,
    meta: {
      hidden: true, // Do not add to navigation
      isOidcCallback: true,
      isPublic: true,
    },
  },
  statuteGroup,
  newsArticle,
  governmentBill,
  environmentBulletin,
]
