import { vuexOidcCreateRouterMiddleware } from "vuex-oidc"
import { createRouter } from "@common/router"
import routes from "./routes"
import config from "@/edita.config.js"

const router = createRouter(config, routes)

// Before OIDC check, save attempted route if not logged in for redirect
router.beforeEach((to, from, next) => {
  if (!router.app.$store.state.auth.loggedIn && !to.fullPath.includes("/auth/")) {
    window.localStorage.setItem("oidc.previousPage", to.fullPath)
  }
  next()
})
// Apply OIDC middleware
router.beforeEach((to, from, next) => vuexOidcCreateRouterMiddleware(router.app.$store)(to, from, next))

export default router
export { routes }
