
import {
  EHS,
  LEGISLATION,
  GOVERNMENT_BILL,
  NEWS,
} from "./material-group"

export default [
  {
    id: "all",
    label: "label.all",
    childGroups: [
      LEGISLATION.id,
      NEWS.id,
      GOVERNMENT_BILL.id,
      EHS.id,
    ],
    selected: true,
  },
  LEGISLATION,
  NEWS,
  GOVERNMENT_BILL,
  EHS,
]
