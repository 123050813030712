/* eslint-disable max-len */
// If necessary, translations can be exploded to separate files:
// - Splitting by language or
// - Splitting by context (documents, users, common service, etc)
import { merge } from "lodash"
import { common } from "@common/config/i18n"

export default merge({}, common, {
  fi: {
    // Customize translations here
    "service.name": "Headpower lakitietopalvelu",
    "service.description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean faucibus congue turpis id dapibus. Quisque tincidunt dui ornare ullamcorper dapibus.",
    "service.description2": "Mauris sodales ipsum ac nulla rutrum, quis efficitur massa aliquet. Etiam ullamcorper, ipsum ut pretium suscipit, urna massa placerat magna, nec bibendum diam eros quis enim.",

    "description.environmentBulletin": "<b>EHS-säädösseuranta</b>-osio sisältää Headpower Oy:n valitsemia Edilexin EHS-tiedotteita. EHS-tiedotteet on asiasanoitettu. Vanhemmat EHS-tiedotteet löytyvät kronologisessa järjestyksessä vuosihakemistosta.",
    "description.euStatuteConsolidated": "",
    "description.governmentBill": "<b>Hallituksen esitykset</b>-osio sisältää Headpower Lakipalvelusta löytyvien lakien muutoksiin ja Lakipalvelun teemoihin liittyvät uusien lakien hallituksen esitykset. Vanhemmat hallituksen esitykset löytyvät kronologisessa järjestyksessä vuosihakemistosta.",
    "description.newsArticle": "<b>Uutiset</b>-osio sisältää Headpower Lakipalvelun teemoja koskevia Edilexin juridisen uutispalvelun uutisia. Uutiset on asiasanoitettu. <br/>Vanhemmat uutiset löytyvät kronologisessa järjestyksessä vuosihakemistosta.",
    "description.statuteConsolidated": "<b>Lainsäädäntö</b>-osio sisältää Headpower Oy:n valitsemia säädöksiä ja määräyksiä, jotka on jaoteltu aihealueittain. Säädökset on asiasanoitettu.",

    "label.login.external": "Kirjaudu sisään Headpower.fi-portaalin kautta",
  },
  sv: {
    // Customize translations here
    "service.name": "Headpower lakitietopalvelu",
    "service.description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean faucibus congue turpis id dapibus. Quisque tincidunt dui ornare ullamcorper dapibus. Mauris sodales ipsum ac nulla rutrum, quis efficitur massa aliquet. Etiam ullamcorper, ipsum ut pretium suscipit, urna massa placerat magna, nec bibendum diam eros quis enim. Morbi in nibh odio. Phasellus viverra, enim sed rhoncus maximus, mi augue cursus erat, eget vulputate velit nibh nec purus. Nulla diam neque, scelerisque id viverra nec, laoreet mattis sapien. Phasellus in tristique nisi.",
  },
  en: {
    // Customize translations here
    "service.name": "Headpower lakitietopalvelu",
    "service.description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean faucibus congue turpis id dapibus. Quisque tincidunt dui ornare ullamcorper dapibus. Mauris sodales ipsum ac nulla rutrum, quis efficitur massa aliquet. Etiam ullamcorper, ipsum ut pretium suscipit, urna massa placerat magna, nec bibendum diam eros quis enim. Morbi in nibh odio. Phasellus viverra, enim sed rhoncus maximus, mi augue cursus erat, eget vulputate velit nibh nec purus. Nulla diam neque, scelerisque id viverra nec, laoreet mattis sapien. Phasellus in tristique nisi.",
  },
})
