import { vuexOidcCreateStoreModule } from "vuex-oidc"
import createStore from "@common/store"
import STORETYPES from "@common/config/store-types"
import config from "@/edita.config.js"
import oidcSettings from "@/config/oidc-settings"

const store = createStore(config)

// Register openidconnect store module
store.registerModule("oidcStore", vuexOidcCreateStoreModule(oidcSettings))

// Watch openidconnect isAuthenticated to store the value in state.loggedIn along with user information
store.watch((state, getters) => getters.oidcIsAuthenticated, (oidcIsAuthenticated) => {
  // TODO: Do not commit directly! Use dispatch
  if (oidcIsAuthenticated) {
    store.commit("auth/login", {
      displayName: store.getters.oidcUser.email,
      userType: "external",
    })

    // Fetch global information only after authenticating, login is required for
    // service so no point trying to get static content before that.
    store.dispatch(`static/${STORETYPES.FETCH_GLOBALLY_REQUIRED_INFORMATION}`)
  } else {
    store.commit("auth/logout")
  }
})

export default store
