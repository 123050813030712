import { merge } from "lodash"
import { makeDefaultMaterialRoute } from "@common/router/utils"

import ArchiveWrapper from "@wrappers/ArchiveWrapper.vue"
import DocumentListWrapper from "@wrappers/DocumentListWrapper.vue"
import DocumentPageWrapper from "@wrappers/DocumentPageWrapper.vue"

export default function(material, { highlight = false, isPublic = false } = {}) {
  return merge({}, makeDefaultMaterialRoute(material), {
    path: "ehs",
    children: [
      {
        path: "",
        name: "environmentBulletin.index",
        component: DocumentListWrapper,
        meta: {
          title: "heading.environmentBulletin.index",
          isPublic: !!isPublic,
        },
      },
      {
        path: ":documentKey(ys\\d+[a-z]{0,1}|reach\\d+[a-z]{0,1})",
        name: "environmentBulletin.documentView",
        component: DocumentPageWrapper,
        meta: {
          validateDocumentLanguage: true,
          isPublic: !!isPublic,
        },
        hidden: true, // Do not show in Nav
      },
      {
        path: "vuosihakemisto/:year(\\d{4})?",
        name: "environmentBulletin.archive",
        component: ArchiveWrapper,
        meta: {
          title: "heading.environmentBulletin.archive",
          isPublic: !!isPublic,
        },
      },
    ],
    highlightItem: highlight,
  })
}
