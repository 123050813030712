
import {
  ENVIRONMENT_BULLETIN,
  EU_STATUTE_CONSOLIDATED,
  GOVERNMENT_BILL as GOVERNMENT_BILL_MATERIAL,
  NEWS_ARTICLE,
  STATUTE_CONSOLIDATED,
} from "./material-type"

const EHS = {
  id: "ehs",
  label: "materialGroup.ehs",
  materialTypes: [ENVIRONMENT_BULLETIN.materialType],
  selected: true,
}

const GOVERNMENT_BILL = {
  id: "governmentBills",
  label: "materialGroup.governmentBills",
  materialTypes: [GOVERNMENT_BILL_MATERIAL.materialType],
  selected: true,
}

const LEGISLATION = {
  id: "legislation",
  label: "materialGroup.legislation",
  materialTypes: [
    STATUTE_CONSOLIDATED.materialType,
    EU_STATUTE_CONSOLIDATED.materialType,
  ],
  selected: true,
}

const NEWS = {
  id: "news",
  label: "materialGroup.news",
  materialTypes: [NEWS_ARTICLE.materialType],
  selected: true,
}

export {
  EHS,
  GOVERNMENT_BILL,
  LEGISLATION,
  NEWS,
}
